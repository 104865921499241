<template>
  <div class="p-2 rounded bg-link-water flex justify-between flex-wrap justify-xs-center align-center">
    <div>
        <md-button class="font-bold">Schedule</md-button>
        <md-button class="bg-primary rounded text-white font-semibold">{{ currentDate }}</md-button>
    </div>
    <div class="pr-2">
        <SelectComponent placeholder="01" :items="day" border-none class="bg-periwinkle-gray rounded" />
        <SelectComponent
            return-type="index"
            @getIndex="getIndex"
            :placeholder="false"
            :items="month"
            border-none
            class="bg-periwinkle-gray rounded mx-2"
        />
        <SelectComponent
            :placeholder="false"
            :items="year"
            border-none
            return-type="object"
            @getObject="getYearIndex"
            class="bg-periwinkle-gray rounded"
        />
    </div>
  </div>
</template>

<script>
import SelectComponent from '@/components/atom/form/SelectComponent'
import {day, month, year } from '@/data/schedule/calendar'
export default {
    components: {
        SelectComponent
    },
    data() {
        return {
            currentDate: new Date().toLocaleString('default', { day: 'numeric', month: "short", year: 'numeric'}),
            day, month, year
        }
    },
    methods: {
        getIndex(item) {
            this.$emit('on-month-change', item)
        },
        getYearIndex(item){
            this.$emit('on-year-change', item.name)
        }
    }
};
</script>
