export const day = [
    {id: 1, name: '01'},
    {id: 2, name: '02'},
    {id: 3, name: '03'},
    {id: 4, name: '04'},
    {id: 5, name: '05'}
];

export const month = [
    {id: 1, name: 'Jan'},
    {id: 2, name: "Feb"},
    {id: 3, name: 'Mar'},
    {id: 4, name: 'Apr'},
    {id: 5, name: "May"},
    {id: 6, name: "Jun"},
    {id: 7, name: "Jul"},
    {id: 8, name: "Aug"},
    {id: 9, name: "Sep"},
    {id: 10, name: "Oct"},
    {id: 11, name: "Nov"},
    {id: 12, name: "Dec"}
];

export const year = [
    {id: 1, name: 2020},
    {id: 2, name: 2019},
    {id: 3, name: 2018},
    {id: 4, name: 2017},
    {id: 5, name: 2016}
];

