<template>
  <div class="md-layout md-gutter px-10">
    <div class="md-layout-item">
      <div class="modal-item text-center rounded-lg p-4 pointer my-1 h-32"
        @click="onShowDialog({
          title: 'Class Schedule',
          content: 'classSchedules',
          bgColor: '#ff4141'
        })"
        style="background: #ff4141">
         <md-icon class="text-white md-size-2x">admin_panel_settings</md-icon>
        <p class="text-white text-uppercase mt-3 text-base font-bold">Class Schedule</p>
      </div>
    </div>
    <div class="md-layout-item">
      <div class="modal-item text-center rounded-lg p-4 pointer my-1 h-32"
        @click="onShowDialog({
          title: 'Special Class',
          content: 'specialClasses',
          bgColor: '#eb5f00'
        })"
        style="background: #eb5f00">
         <md-icon class="text-white md-size-2x">admin_panel_settings</md-icon>
        <p class="text-white text-uppercase mt-3 text-base font-bold">Special Class</p>
      </div>
    </div>
    <div class="md-layout-item">
      <div class="modal-item text-center rounded-lg p-4 pointer my-1 h-32"
        @click="onShowMakeAnAppointment()"
        style="background: #37ab00">
         <md-icon class="text-white  md-size-2x">admin_panel_settings</md-icon>
        <p class="text-white text-uppercase mt-3 text-base font-bold">Make an appointment</p>
      </div>
    </div>
    <div class="md-layout-item">
      <div class="modal-item text-center rounded-lg p-4 pointer my-1 h-32"
        @click="onShowDialog({
          title: 'Seminar Schedule',
          content: 'workshops',
          bgColor: '#bf01bd'
        })"
        style="background: #bf01bd">
        <md-icon class="text-white  md-size-2x">admin_panel_settings</md-icon>
        <p class="text-white text-uppercase mt-3 text-base font-bold">Workshop</p>
      </div>
    </div>
    <div class="md-layout-item">
      <div class="modal-item text-center rounded-lg p-4 pointer my-1 h-32"
        @click="onShowDialog({
          title: 'Live Class',
          content: 'liveClasses',
          bgColor: '#0093ad'
        })"
        style="background: #0093ad"
      >
        <md-icon class="text-white  md-size-2x">admin_panel_settings</md-icon>
        <p class="text-white text-uppercase mt-3 text-base font-bold">Live class</p>
      </div>
    </div>
  </div>
</template>

<script>
import data from "@/data/schedule/data"

export default {
   methods: { 
    onShowDialog({ title, bgColor, content }) {
      this.$emit('on-show-dialog', {
        component : 'TabularContentDialog',
        title : title,
        content : data[content],
        bgColor : bgColor,
      })
    },
    onShowMakeAnAppointment() {
      this.$emit('on-show-dialog', {
        component: 'MakeAnAppointment',
        title : '',
        content : '',
        bgColor : '',
      })
    }
  },
}
</script>
