const classSchedules = [];
for (let i = 0; i < 15; i++) {
    classSchedules.push({
        id: i,
        topic: 'Reading',
        description: 'Reading, MCQ Choose Single Answer Practice',
        date: '12 May, 2019',
        time: '02:00pm',
        status: ( i < 7 ) ? 'completed': 'upcoming'
    });
}

const specialClasses = [];
for (let i = 0; i < 15; i++) {
    specialClasses.push({
        id: i,
        className: 'Moonlight',
        description: 'Reading, MCQ Choose Single Answer Practice',
        date: '12 May, 2019',
        time: '02:00pm',
        status: ( i < 7 ) ? 'completed': 'upcoming'
    });
}

const workshops = [];
for (let i = 0; i < 15; i++) {
    workshops.push({
        id: i,
        seminarName: 'Reading',
        seminarOn: 'Reading',
        venue: 'Dhanmondi Branch',
        date: '12 May, 2019',
        time: '02:00pm',
        status: ( i < 7 ) ? 'completed': 'upcoming'
    });
}

const liveClasses = [];
for (let i = 0; i < 15; i++) {
    liveClasses.push({
        id: i,
        className: 'Reading',
        description: 'Reading, MCQ Choose Single Answer Practice',
        medium: 'Zoom',
        onlineLink: 'https://ewrwersdfwerewr',
        date: '12 May, 2019',
        time: '02:00pm',
        status: ( i < 7 ) ? 'completed': 'upcoming'
    });
}

export default {
    classSchedules,
    specialClasses,
    liveClasses,
    workshops
}