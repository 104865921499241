<template>
  <div class="p-3">
    <div class="p-2 rounded"
        :style="{'backgroundColor': bgColor}">
      <div class="flex justify-between align-center flex-wrap justify-sm-center justify-xs-center">
        <h2 class="text-white text-uppercase font-bold text-lg ml-2">{{ title }}</h2>
        <div class="flex justify-end m-1">
          <SelectComponent
            border-none
            class="rounded w-64"
            :style="{'backgroundColor': adjust(bgColor, 20)}"
          />
        </div>
      </div>
    </div>
    <md-table>
        <md-table-row>
        <md-table-head 
          v-for="(key, index) in kyes" :key="index"
          class="text-uppercase"
          >
          {{ key.replace( /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g, '$1$4 $2$3$5' ) }}
        </md-table-head>
        </md-table-row>

        <md-table-row v-for="item in content" :key="item.id">
          <md-table-cell v-for="(key, index) in kyes" :key="index">{{ item[key] }}</md-table-cell>
          <md-table-cell v-if="item.status == 'completed'">
              <span class="bg-success text-sm text-white px-1 rounded">Completed</span>
          </md-table-cell>
          
          <md-table-cell v-else-if="item.status == 'upcoming'">
              <span class="bg-danger text-sm text-white px-1 rounded">upcoming</span>
          </md-table-cell>

        </md-table-row>

      </md-table>
      
      <div class="flex justify-end mt-8 mb-4">
        <md-button class="text-uppercase outline-gray-400 rounded">Synchronized</md-button>
        <md-button class="text-uppercase rounded bg-success text-white">Download</md-button>
      </div>
  </div>
</template>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  

<script>
import { SelectComponent } from "@/components";

export default {
  components: {
    SelectComponent,
  },
  props: {
    title: {
        type: String,
        default: () => 'Title goes to here'
    },
    content: {
        type: Array,
        required: true
    },
    bgColor: {
        type: String,
        default: () => ''
    }
  },
  computed: {
    kyes() {
      if(this.content.length > 0) {
       return Object.keys(this.content[0])
      }
      return []
    }
  },
  methods: {
    adjust(color, amount) {
        return '#' + color.replace(/^#/, '')
            .replace(/../g, color => 
                ('0'+Math
                    .min(255, Math
                    .max(0, parseInt(color, 16) + amount))
                    .toString(16))
                    .substr(-2)
                );
        }
  }
};
</script>