<template>
  <form @submit.prevent="onSubmit" class="p-6">
      <DialogTitle bg-color="bg-success" label="Make an appointment" align="center" />
      <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-100">
              <InputFieldComponent
                label="Appointment Name"
                class="w-50p m-3"
                v-model.trim="$v.form.appointmentName.$model"
                :message="!$v.form.appointmentName.required && $v.form.appointmentName.$dirty ? 'Field is required' : null"
              />
          </div>
          <div class="md-layout-item md-size-50">
              <SelectComponent
                label="Choose Teacher"
                :items="teachers"
                class="w-74 m-3"
                v-model.trim="$v.form.teacherId.$model"
                :message="!$v.form.teacherId.required && $v.form.teacherId.$dirty ? 'Field is required' : null"
                />
          </div>
          <div class="md-layout-item md-size-50">
              <SelectComponent
                label="Choose Date"
                :items="dates"
                class="w-74 m-3"
                v-model.trim="$v.form.date.$model"
                :message="!$v.form.date.required && $v.form.date.$dirty ? 'Field is required' : null"
              />
          </div>
          
          <div class="md-layout-item md-size-50">
              <SelectComponent
                label="Choose Time"
                :items="time"
                class="w-74 m-3"
                v-model.trim="$v.form.time.$model"
                :message="!$v.form.time.required && $v.form.time.$dirty ? 'Field is required' : null"
                />
          </div>
          <div class="md-layout-item md-size-50">
              <SelectComponent
                label="Choose Topic"
                :items="topics"
                class="w-74 m-3"
                v-model.trim="$v.form.topicId.$model"
                :message="!$v.form.topicId.required && $v.form.topicId.$dirty ? 'Field is required' : null"
                />
          </div>
          <div class="md-layout-item md-size-100">
              <TextAreaComponent
                label-class="text-basew"
                label="Notes"
                class="m-3"
                v-model.trim="$v.form.notes.$model"
                :message="!$v.form.notes.required && $v.form.notes.$dirty ? 'Field is required' : null"
                />
          </div>
          <div class="md-layout-item md-size-100 flex justify-end mt-6">
              <md-button type="submit" class="bg-victoria rounded text-white mr-0">Create Appointment</md-button>
          </div>
      </div>
  </form>
</template>
<script>
import {
    DialogTitle,
    SelectComponent,
    TextAreaComponent,
    InputFieldComponent,
} from "@/components";
import { required } from "vuelidate/lib/validators";
import cms from "@/data/cms"
import { mapMutations } from "vuex";

export default {
    components: {
        DialogTitle,
        SelectComponent,
        TextAreaComponent,
        InputFieldComponent,
    },
    data() {
        return {
          dates: cms.dates,
          topics: cms.topics,
          teachers: cms.teachers,
            form: {
                appointmentName: "",
                teacherId: "",
                date: "",
                time: "",
                topicId: "",
                notes: "",

            }
        }
    },
    computed: {
      time() {
        return cms.minutes.map(item => {
            return {
              id: item.id,
              name: item.name + ':' +item.name
            }
        })
      }
    },
    methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    onSubmit() {
      this.$v.$touch();
      if(this.$v.$invalid) {
        return;
      }
      this.setShowSnackbar('Appointment saved successfully !');
      setTimeout(() => {
       this.dialog(false);
      }, 1000);
    }
  },
  validations: {
    form: {
      appointmentName: {required},
      teacherId: {required},
      date: {required},
      time: {required},
      topicId: {required},
      notes: {required}
    }
  }
}
</script>

<style>

</style>